import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import Post from 'components/Post'

export default ({ location, data: { wordpress } }) => (
  <Layout location={location} type="archive">
    <header className="page-header">
			<h1 className="page-title">Author Archives: <span className="page-description">{wordpress.user.name}</span></h1>
    </header>
    {wordpress.posts && wordpress.posts.edges.map(post => (
      <Post key={post.node.id} post={post} />
    ))}
  </Layout>
)

export const query = graphql`
  query($id: ID!, $authorId: Int!) {
    wordpress {
      user(id: $id) {
        name
      }
      posts(where: { author: $authorId }) {
        edges {
          node {
            id
            postId
            slug
            title
            excerpt
            date
            dateGmt
            modified
            modifiedGmt
            commentCount
            author {
              name
              slug
            }
            tags {
              edges {
                node {
                  slug
                  name
                }
              }
            }
            categories {
              edges {
                node {
                  slug
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
